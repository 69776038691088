import styled from 'styled-components';
import { breakpoint } from '../../styles';
import { SocialLinkContainer } from '../SocialLinks/styles';

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 20px;
  z-index: 9999;

  ${breakpoint(
    'large',
    `
  padding: 15px 50px;
  `
  )}

  ${SocialLinkContainer} {
    display: none;

    ${breakpoint(
      'medium',
      `
      display: flex;
    `
    )}
  }
`;

export const Logo = styled.div``;
