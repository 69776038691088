import React from 'react';
import {
  PreHeader,
  Section,
  Container,
  Link,
  LinkContainer
} from '../../../styles.js';
import Arrow from '../../../Components/Arrow/index.js';
import styled from 'styled-components';

export default function Intro({ mode }) {
  return (
    <Section mode={mode}>
      <Container>
        <PreHeader>Nice to meet you</PreHeader>
        <h1>I’m Calvin.</h1>
        <Copy>
          A South African born, London based freelance UX/UI designer. I have worked with a range of startups and established brands including Spotify, Adidas, Amazon and Perkbox. Take a look around and if you like what you see, let’s create something cool together.
        </Copy>
        <LinkContainer>
          <div>
            <Link href="/calvin_bowen_CV_2020.pdf" target="_blank">
              Download my CV <Arrow />
            </Link>
          </div>
        </LinkContainer>
      </Container>
    </Section>
  );
}

const Copy = styled.p`
  max-width: 550px;
  width: 100%;
`;
