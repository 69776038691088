import styled from 'styled-components';
import { breakpoint } from '../../styles';

export const FooterWrapper = styled.footer`
  background: ${props => props.theme.backgroundLight};
  color: ${props => props.theme.fontColorDark};
  font-size: 14px;
  padding: 10px 15px;

  ${breakpoint(
    'large',
    `
  padding: 15px 50px;
  `
  )}
`;

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  & > * {
    margin-bottom: 20px;
  }

  & > :first-child {
    margin-bottom: 0px;
  }

  ${breakpoint(
    'medium',
    `
  justify-content: space-between;
  flex-direction: row;
  & > * {
    margin-bottom: 0px;
  }
  `
  )}
`;
