import React from 'react';
import Home from './Home';
import { Switch, Route, Redirect } from 'react-router-dom';

export const routeConfig = [
  {
    path: '/',
    exact: true,
    component: Home
  }
];

export default function Routes(props) {
  return (
    <Switch>
      {routeConfig.map(({ exact, path, component }) => {
        return (
          <Route key={path} exact={exact} path={path} component={component} />
        );
      })}
      <Redirect to="/" />
    </Switch>
  );
}
