import React from 'react';
import { SocialLink, SocialLinkContainer } from './styles';

const links = [
  {
    url: 'https://www.linkedin.com/in/calvin-bowen-4b913142/',
    light: 'linkedin.svg',
    dark: 'linkedin-dark.svg'
  },
  {
    url: 'https://www.behance.net/calvinbowen',
    light: 'behance.svg',
    dark: 'behance-dark.svg'
  },
  {
    url: 'https://dribbble.com/CalvinBowen',
    light: 'dribble.svg',
    dark: 'dribble-dark.svg'
  }
];

export default function SocialLinks({ mode }) {
  return (
    <SocialLinkContainer>
      {links.map((link, index) => {
        return (
          <SocialLink
            key={`${mode}-social-${index}`}
            href={link.url}
            target="_blank"
          >
            <img src={link[mode]} alt={link.alt} />
          </SocialLink>
        );
      })}
    </SocialLinkContainer>
  );
}
