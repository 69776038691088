import React from 'react';

export default function Arrow() {
  return (
    <svg
      width="19px"
      height="18px"
      viewBox="0 0 19 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Combined Shape</title>
      <desc>Created with Sketch.</desc>
      <g
        id="IDEAS"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1.0_desktop"
          transform="translate(-624.000000, -1299.000000)"
          fill="#222222"
          fillRule="nonzero"
        >
          <g id="intro" transform="translate(150.000000, 936.000000)">
            <g id="cta" transform="translate(247.000000, 351.000000)">
              <g id="inner" transform="translate(21.484716, 5.000000)">
                <path
                  d="M214.847162,7.5 C219.89607,7.5 223.978166,11.3 223.978166,16 C223.978166,20.7 219.89607,24.5 214.847162,24.5 C209.798253,24.5 205.716157,20.7 205.716157,16 C205.716157,11.3 209.798253,7.5 214.847162,7.5 Z M214.847162,8.5 C210.442795,8.5 206.790393,11.9 206.790393,16 C206.790393,20.1 210.442795,23.5 214.847162,23.5 C219.251528,23.5 222.90393,20.1 222.90393,16 C222.90393,11.9 219.251528,8.5 214.847162,8.5 Z M212.591266,15.1 L214.632314,17 C214.739738,17.1 214.954585,17.1 215.169432,17 L217.21048,15.1 C217.425328,14.9 217.747598,14.9 217.962445,15.1 C218.177293,15.3 218.177293,15.6 217.962445,15.8 L215.921397,17.7 C215.599127,17.9 215.169432,18.1 214.847162,18.1 C214.524891,18.1 214.095197,18 213.880349,17.7 L211.839301,15.8 C211.624454,15.6 211.624454,15.3 211.839301,15.1 C212.054148,14.9 212.376419,14.9 212.591266,15.1 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
