import React from 'react';
import { FooterWrapper, FooterContent } from './styles';
import SocialLinks from '../SocialLinks';

export default function Footer() {
  return (
    <FooterWrapper>
      <FooterContent>
        <div>© Copyright 2020. All rights reserved</div>
        <SocialLinks mode={'dark'} />
      </FooterContent>
    </FooterWrapper>
  );
}
