import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { GlobalStyles, breakpoints } from './styles';
import styled, { ThemeProvider } from 'styled-components';
import Cursor from './Components/Cursor';
import Scroller from './Components/Scroller';
import Loader from './Components/Loader';
import ReactGA from 'react-ga';

const themes = {
  dark: {
    primary: '#FB5D5F',
    backgroundDark: '#1c1c1c',
    backgroundMedium: '#f1f1f1',
    backgroundLight: '#fff',
    fontColorLight: '#fff',
    fontColorMedium: '#838383',
    fontColorDark: '#3C3C3C',
    headingColorDark: '#222',
    cursorColor: '#C5C5C5'
  }
};

const ThemeContext = React.createContext(themes.dark);

function App() {
  const theme = useContext(ThemeContext);
  const [started, setStarted] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    ReactGA.initialize('UA-156573638-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setWidth]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div style={{ opacity: started ? 1 : 0, transsition: 'opacity 0.6s' }}>
        {width >= breakpoints.medium && <Header />}
        <Scroller>
          {width < breakpoints.medium && <MobileHeader />}
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          <Footer />
        </Scroller>
      </div>
      <Loader
        started={started}
        loadTime={3000}
        onLoaded={() => {
          setStarted(true);
        }}
      />
      {width >= breakpoints.large && <Cursor />}
    </ThemeProvider>
  );
}

export default App;

const MobileHeader = styled(Header)`
  position: absolute;
`;
