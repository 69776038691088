import React from 'react';
import {
  PreHeader,
  Section,
  Container,
  Link,
  LinkContainer
} from '../../../styles.js';
import Arrow from '../../../Components/Arrow/index.js';

export default function Contact({ mode }) {
  return (
    <Section id="contact" mode={mode}>
      <Container>
        <PreHeader>Get in touch</PreHeader>
        <h1>Let’s work together</h1>
        <p>
          Feel free to pop me an email at <b>calvinmarkbowen@gmail.com.</b>
        </p>
        <p>I look forward to hearing from you!</p>
        <LinkContainer>
          <div>
            <Link href="mailto:calvinmarkbowen@gmail.com">
              Start a convo <Arrow />
            </Link>
          </div>
        </LinkContainer>
      </Container>
    </Section>
  );
}
