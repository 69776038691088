import React, { useEffect, useRef } from 'react';
import {
  Section,
  Container,
  HeroTitle,
  HeroSubtitle,
  breakpoint,
  spin,
  ScrollLabel
} from '../../../styles.js';
import { clamp } from 'lodash';
import { TweenLite } from 'gsap';
import styled from 'styled-components';
import { ReactComponent as Spinner } from './spinner_3.svg';
import SpinnerImg from './spinner_3.svg';

export default function Hero({ mode }) {
  const followerContainer = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    const onMouseMove = e => {
      const { clientX, clientY } = e;
      const { top, left } = followerContainer.current.getBoundingClientRect();

      TweenLite.to(followerContainer.current, 5, {
        y: clamp(clientY - top, -15, 15),
        x: clamp(clientX - left, -15, 15),
        overwrite: 'all'
      });
    };
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('resize', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', onMouseMove);
    };
  }, []);

  const goToSection = () => {
    const section = document.getElementById('contact');

    console.log(section.offsetTop);

    window.scrollTo(0, section.offsetTop);
  };

  return (
    <Wrapper mode={mode}>
      <HeroContainer
        ref={node => (container.current = node)}
        style={{ position: 'relative' }}
      >
        <InnerWrapper>
          <HeroTitle>Hello</HeroTitle>
          <HeroSubtitle>
            I'm a <b>UX/UI Designer</b> in London
          </HeroSubtitle>
          <HeroSpinnerWrapper ref={node => (followerContainer.current = node)}>
            <ScrollButton onClick={goToSection}>
              <HeroSpinner src={SpinnerImg} />
            </ScrollButton>
          </HeroSpinnerWrapper>
        </InnerWrapper>
        <ScrollLabelWrapper>
          <ScrollLabel>Scroll</ScrollLabel>
        </ScrollLabelWrapper>
      </HeroContainer>
    </Wrapper>
  );
}

const ScrollLabelWrapper = styled.span`
  position: absolute;
  transform-origin: right;
  transform: rotate(90deg);
  right: 25px;
  bottom: 75px;
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const HeroContainer = styled(Container)`
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 120px;
`;

const Wrapper = styled(Section)`
  position: relative;
  height: 640px;
  padding-bottom: 0;

  ${breakpoint(
    'medium',
    `
  padding-bottom: 0;
  `
  )}
`;

export const HeroSpinner = styled(Spinner)`
  animation: ${spin} linear 20s infinite;
  pointer-events: none;

  path {
    stroke: ${props => props.theme.primary};
    transition: stroke 0.3s;
  }
`;

export const HeroSpinnerWrapper = styled.div`
  right: 50px;
  top: -50px;
  display: none;
  position: absolute;

  ${breakpoint(
    'medium',
    `
  display: block;
`
  )}
`;

const ScrollButton = styled.button`
  background: none;
  appearance: none;
  border: none;
  outline: none;
  box-shadow: none;
  position: relative;
  width: 110px;
  height: 110px;

  &:before {
    content: '';
    background: ${props => props.theme.primary};
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
  }

  &:hover:before {
    transform: scale(1);
    opacity: 1;
  }

  &:hover path {
    stroke: #fff;
  }
`;
