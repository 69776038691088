import styled from 'styled-components';

export const SocialLinkContainer = styled.div`
  display: flex;
`;

export const SocialLink = styled.a`
  margin: 0 17px;
  display: block;
`;
