import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export default function Loader({ loadTime, onLoaded, started }) {
  const [fadedIn, setFadedIn] = useState(false);

  useEffect(() => {
    setFadedIn(true);
    const timeout = setTimeout(() => {
      onLoaded && onLoaded();
    }, loadTime);

    return () => {
      clearTimeout(timeout);
    };
  }, [onLoaded, loadTime]);

  return (
    <LoaderWrap
      style={{
        opacity: !started && fadedIn ? 1 : 0,
        visibility: !started && fadedIn ? 'visible' : 'hidden'
      }}
    >
      <img src="portfolio_title_white.svg" alt="loading" />
      <ProgressBar />
    </LoaderWrap>
  );
}

const ProgressBar = styled.div`
  max-width: 100%;
  width: 230px;
  height: 2px;
  position: relative;
  background: #838383;
  margin-top: 50px;
  border-radius: 1px;
  overflow: hidden;

  &:after {
    content: '';
    background: #fb5d5f;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform-origin: left;
    animation: 3s load infinite;
  }
`;

const LoaderWrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.backgroundDark};
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity 0.6s, visibility 0.6s;
`;
