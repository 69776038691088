import React from 'react';
import Hero from './Hero';
import Intro from './Intro';
import Works from './Works';
import Contact from './Contact';

const projects = [
  {
    name: 'Spotify Resonate',
    url: 'https://www.behance.net/gallery/92988195/Spotify-Resonate'
  },
  {
    name: 'Adidas Boost',
    url: 'https://www.behance.net/gallery/89699501/Adidas-Boost'
  },
  {
    name: 'The Yacht Week',
    url: 'https://www.behance.net/gallery/89668973/Sound-Waves-The-Yacht-Week'
  },
  {
    name: 'Design-a-day',
    url: 'https://www.behance.net/gallery/90973381/DESIGN-A-DAY'
  }
];

export default function Home() {
  return (
    <>
      <Hero mode="dark" />
      <Intro mode="light" />
      <Works mode="medium" projects={projects} />
      <Contact mode="dark" />
    </>
  );
}
