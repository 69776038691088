import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';
import { gsap, Power1 } from 'gsap/all';
//import useScrollPosition from '../../hooks/useScrollPosition';
import { ScrollerWrapper } from './styles';

export default ({ children }) => {
  const container = useRef(null);
  //const position = useScrollPosition(0);
  const [containerSize, setContainerSize] = useState(0);
  const sections = useRef(null);

  useEffect(() => {
    sections.current = [...document.querySelectorAll('section > div')];
    gsap.set(sections.current, { opacity: 0, y: 50 });
  }, []);

  useEffect(() => {
    const onScroll = () => {
      sections.current.forEach(elem => {
        if (!elem.fadedIn) {
          const { top, height } = elem.getBoundingClientRect();
          if (
            window.innerHeight - top >= window.innerHeight / 5 ||
            top <= height
          ) {
            elem.fadedIn = true;
            gsap.to(elem, 0.3, { opacity: 1, y: 0, overwrite: 'auto' });
          }
        }
      });
    };

    const smoothScroll = () => {
      gsap.to(container.current, 1, {
        y: -window.scrollY,
        overwrite: true,
        ease: Power1.easeOut,
        onUpdate: sections.current && onScroll
      });
    };

    onScroll();
    smoothScroll();
    window.addEventListener('scroll', smoothScroll);

    return () => {
      window.removeEventListener('scroll', smoothScroll);
    };
  }, [container]);

  useLayoutEffect(() => {
    function onResize() {
      setContainerSize(container.current.offsetHeight);
    }

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setContainerSize]);

  useLayoutEffect(() => {
    document.body.style.height = `${containerSize}px`;
  }, [containerSize]);

  return <ScrollerWrapper ref={container}>{children}</ScrollerWrapper>;
};
