import styled, { createGlobalStyle, keyframes } from 'styled-components';

export const breakpoints = {
  small: 0,
  medium: 640,
  large: 920
};

export const breakpoint = (screen, styles) => {
  const size = breakpoints[screen] || breakpoints.small;

  return `@media only screen and (min-width: ${size}px){
    ${styles}
  }`;
};

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Aileron-Black';
    src: url('Aileron-Black.eot');
    src: url('Aileron-Black.eot?#iefix') format('embedded-opentype'),
        url('Aileron-Black.svg#Aileron-Black') format('svg'),
        url('Aileron-Black.ttf') format('truetype'),
        url('Aileron-Black.woff') format('woff'),
        url('Aileron-Black.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    outline: 0;
  }

  body,html {
    padding: 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.7;

    ${breakpoint(
      'medium',
      `
      font-size: 18px;
    `
    )}
  }

  body {
    background: ${props => props.theme.backgroundDark};
    color: ${props => props.theme.fontColorLight};
    font-family: 'Roboto';
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    ${breakpoint(
      'large',
      `
      cursor: none;
    `
    )}
  }

  b {
    font-weight: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    line-height: 1.2;
  }

  h1 {
    font-size: 32px;
    font-family: Aileron-Black;

    ${breakpoint(
      'medium',
      `
      font-size: 52px;
    `
    )}
  }

  a,
  a:active,
  a:focus {
    color: inherit;

    ${breakpoint(
      'large',
      `
      cursor: none;
    `
    )}
  }

  a, button {
    display: inline-block;
    line-height: 1;
    text-decoration: none;
  }

  input {
    font-size: inherit;
  }

  input,
  button,
  label {
    ${breakpoint(
      'large',
      `
      cursor: none;
    `
    )}
  }

  input[type='text'],
  input[type='email'] {
    border: none;
    border-bottom: 1px solid ${props => props.theme.backgroundLight};
    background: none;
    padding: 10px 0;
  }
`;

export const spin = keyframes`
  from { transform: rotate(0)}
  to { transform: rotate(360deg)}
`;

export const Marker = styled.div`
  color: ${props => props.theme.primary};
  font-size: 14px;
  letter-spacing: 2px;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 500;

  &:before {
    content: '';
    height: 1px;
    background: ${props => props.theme.primary};
    width: 54px;
    position: absolute;
    top: calc(50% - 1px);
    left: calc(100% + 15px);
  }

  &:after {
    content: '';
    position: absolute;
    height: 7px;
    width: 7px;
    border: 1px solid ${props => props.theme.primary};
    left: calc(100% + 69px);
    top: calc(50% - 5px);
    border-radius: 50%;
  }
`;

export const PreHeader = styled(Marker)`
  margin-bottom: 11px;
`;

export const ScrollLabel = styled(Marker)``;

export const Section = styled.section`
  padding: 120px 15px;
  width: 100%;
  min-height: 100vh;
  display: flex;

  ${breakpoint(
    'medium',
    `
  padding: 120px 70px;
  `
  )}

  ${props => {
    const modes = {
      dark: `background: ${props.theme.backgroundDark};
      color: ${props.theme.fontColorMedium};
       h1, b, a, input, button {
        color: ${props.theme.fontColorLight};
       }
       path {
        fill: ${props.theme.fontColorLight};
       }
      `,
      light: `background: ${props.theme.backgroundLight};
      position: relative;
      color: ${props.theme.fontColorDark};
      h1 {
        color: ${props.theme.headingColorDark};
       }
       
       path {
        fill: ${props.theme.fontColorLight};
        ${breakpoint(
          'medium',
          `
        fill: ${props.theme.fontColorDark};
        `
        )}
       }

       &:before,
       &:after {
         content: '';
         left: 0;
         right: 0;
         position: absolute;
         height: 40px;
         background: url(tile_repeat.jpg);
         background-repeat: repeat-x;
         background-size: 40px;
       }

       &:before {
        top: 0;
       }

       &:after {
         bottom: 0;
       }
      `,
      medium: `background: ${props.theme.backgroundMedium};
      color: ${props.theme.fontColorDark};
      `
    };
    return modes[props.mode] || modes.dark;
  }}
`;

export const Container = styled.div`
  width: 920px;
  max-width: 100%;
  margin: auto;
`;

export const HeroTitle = styled.h1`
  font-size: 60px;
  line-height: 1.2;
  margin: 0;

  ${breakpoint(
    'medium',
    `
    font-size: 120px;
  `
  )}
`;

export const HeroSubtitle = styled.h2`
  font-size: 32px;
  margin: 0;
  line-height: 1.2;
  font-weight: 300;
  color: ${props => props.theme.fontColorMedium};

  b {
    color: ${props => props.theme.fontColorLight};
  }

  ${breakpoint(
    'medium',
    `
  font-size: 52px;
  `
  )}
`;

const getButtonStyles = props => `
appearance: none;
background: none;
border: none;
padding: 5px 20px;
letter-spacing: 2px;
position: relative;
z-index: 1;
height: 40px;
font-size: 14px;
line-height: 1.7;
font-family: inherit;
color: ${props.theme.fontColorLight};
transition: color 0.6s;
font-weight: 500;
display: inline-flex;
align-items: center;
flex-grow: 0;

${breakpoint(
  `large`,
  `
  color: ${props.mode === 'dark' ? props.theme.fontColorLight : 'inherit'};
`
)}

svg {
  content: '';
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-left: 5px;
  ${props.direction === 'right' && 'transform: rotate(-90deg)'};
  flex-shrink: 0;

  path {
    transition: fill 0.6s;
  }
}

&:before {
  content: '';
  background: ${props.theme.primary};
  opacity: 1;
  z-index: -1;
  width: 100%;
  height: 40px;
  left: 0;
  position: absolute;
  border-radius: 20px;
  transition: width 0.6s, opacity 0.6s;
  pointer-events: none;

  ${breakpoint(
    `large`,
    `
    width: 40px;
    opacity: 0.2;
  `
  )}
}

&:hover {
  color: ${props.theme.fontColorLight};
  &:before {
    width: 100%;
    opacity: 1;
  }

  path {
    fill: ${props.theme.fontColorLight};
  }
}
`;

export const Link = styled.a`
  ${props => getButtonStyles(props)}
`;

export const Button = styled.button`
  ${props => getButtonStyles(props)}
`;

export const LargeLink = styled.a`
  display: block;
  font-size: 42px;
  line-height: 1.2;
  font-weight: bold;
  font-family: Aileron-Black;
  position: relative;
  display: inline-block;
  margin: 8px 0;

  &,
  &:active,
  &:focus,
  &:hover {
    color: ${props => props.theme.headingColorDark};
  }

  ${breakpoint(
    'medium',
    `
    font-size: 100px;
    margin: 20px 0;
  `
  )}

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${props => props.theme.primary};
    z-index: -1;
    transform: scaleX(0);
    transition: transform 0.6s;
    transform-origin: left;
  }

  &:hover:before {
    transform: scaleX(1);
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  a:not(:last-child) {
    margin-bottom: 20px;
  }

  ${breakpoint(
    'medium',
    `
    flex-direction: row;
    a:not(:last-child) {
      margin-right: 40px;
      margin-bottom: 0;
    }
  `
  )}
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 60px;

  input {
    width: 100%;
  }
`;

export const Label = styled.label`
  position: absolute;
  top: 50%;
  transform: translateY(${props => (props.isDirty ? '-150%' : '-50%')});
  transition: transform 0.3s;
  left: 0;

  input:focus + & {
    transform: translateY(-150%);
  }
`;

export const Justified = styled.div`
  display: flex;
  justify-content: space-between;

  ${InputWrapper} {
    ${breakpoint(
      'medium',
      `
    width: 50%;
    &:not(:last-child) {
      margin-right: 80px;
    }
    `
    )}
  }
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${breakpoint(
    'medium',
    `
  flex-direction: row;
  `
  )}

  ${InputWrapper} {
    ${breakpoint(
      'medium',
      `
  width: 50%;
  &:not(:last-child) {
    margin-right: 80px;
  }
  `
    )}
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
`;
