import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledCursor = styled.div`
  height: 36px;
  width: 36px;
  position: fixed;
  top: -40px;
  left: -40px;
  border: 4px solid ${props => props.theme.cursorColor};
  border-radius: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

export default function Cursor() {
  const cursor = useRef;

  useEffect(() => {
    const onMouseMove = e => {
      cursor.current.style.top = `${e.clientY}px`;
      cursor.current.style.left = `${e.clientX}px`;
    };

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [cursor]);
  return (
    <StyledCursor
      ref={node => {
        cursor.current = node;
      }}
    />
  );
}
