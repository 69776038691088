import React, { useEffect, useRef } from 'react';
import { HeaderWrapper, Logo } from './styles';
import { Justified } from '../../styles';
import SocialLinks from '../SocialLinks';
import LogoSVG from './logo_full.js';
import { gsap } from 'gsap/all';

export default function Header() {
  const links = useRef(null);

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset > 50) {
        gsap.to('#alvin', 0.3, { opacity: 0, overwrite: 'all' });
        gsap.to(links.current, 0.3, { opacity: 0, overwrite: 'all' });
      } else {
        gsap.to('#alvin', 0.3, { opacity: 1, overwrite: 'all' });
        gsap.to(links.current, 0.3, { opacity: 1, overwrite: 'all' });
      }
    };

    onScroll();
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <HeaderWrapper>
      <Justified>
        <Logo dangerouslySetInnerHTML={{ __html: LogoSVG }}></Logo>
        <div
          ref={node => {
            links.current = node;
          }}
        >
          <SocialLinks mode={'light'} />
        </div>
      </Justified>
    </HeaderWrapper>
  );
}
