import React from 'react';
import { PreHeader, Section, Container, LargeLink } from '../../../styles.js';

export default function Works({ projects, mode }) {
  return (
    <Section mode={mode}>
      <Container>
        <PreHeader>Some of my work</PreHeader>
        <div>
          {projects.map(({ name, url }) => {
            return (
              <div key={url}>
                <LargeLink href={url} target="_blank">
                  {name}
                </LargeLink>
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  );
}
